import React, { useState } from "react";
import axios from 'axios';
import FamilyOwned from "../Common/FamilyOwned";
import Slideshow from "../Common/Slideshow";
import ReCAPTCHA from "react-google-recaptcha";
import { homePage } from "../../models/homePage";
import { contactPage } from "../../models/contactPage";

const createDOMPurify = require("dompurify");
const { JSDOM } = require("jsdom");

const window = new JSDOM("").window;
const DOMPurify = createDOMPurify(window);

interface Props {
  siteConfig: homePage;
  contactData: contactPage;
}

const Contact = (props: Props) => {
  const siteConfig = props.siteConfig;
  const contactData = props.contactData;
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [attention, setAttention] = useState("");
  const [message, setMessage] = useState("");
  const [captcha, setCaptcha] = useState(false);
  const [dataPosted, setDataPosted] = useState(false);

  const handleFirstName = (e: any) => {
    setFirstName(e.target.value);
  };

  const handleLastName = (e: any) => {
    setLastName(e.target.value);
  };

  const handleEmail = (e: any) => {
    setEmail(e.target.value);
  };

  const handlePhoneNumber = (e: any) => {
    setPhoneNumber(e.target.value);
  };

  const handleAttention = (e: any) => {
    setAttention(e.target.value);
  };

  const handleMessage = (e: any) => {
    setMessage(e.target.value);
  };

  const onCaptcha = (value) => {
    setCaptcha(true);
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
    if (!/^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/.test(email)) {
      const emailInvalid = document.getElementById('email-input') as HTMLElement;
      const emailInvalidLabel = document.getElementById('email-label') as HTMLElement;
      emailInvalid.style.color = 'red';
      emailInvalidLabel.style.display = 'inline-block';
      setTimeout(() => {
        emailInvalid.style.color = 'black';
        emailInvalidLabel.style.display = 'none';
      }, 5000);
    }
    if (captcha) {
      const postData = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        phoneNumber: phoneNumber,
        attention: attention,
        msg: message,
      };
      //api HTTP POST
      axios.post('https://admin.maxwellhardwoodflooring.com/sendmail', postData)
      setDataPosted(true);
    } else {
      const captchaErr = document.evaluate( '//*[@id="captcha-Vx"]/div/div/div' ,document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null ).singleNodeValue as HTMLElement;
      captchaErr.style.border = "1px solid red"
    }
  };

  return (
    <div className="contact-us-page">
      {contactData && (
        <section
          className="contact-banner"
          style={{
            backgroundImage:
              "url(" +
              contactData?.HeaderContent![0]?.HeaderImage?.url +
              ")",
              backgroundRepeat: "no-repeat",
              backgroundPosition: `${contactData.HeaderContent![0].ImageXAxis?.toString()}% ${contactData.HeaderContent![0].ImageYAxis?.toString()}%`,
              backgroundSize: "cover",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-10">
                <div className="text-box">
                  <h2
                    dangerouslySetInnerHTML={{
                      __html: 
                        contactData?.HeaderContent![0]?.HeaderText
                      ,
                    }}
                  ></h2>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      <section className="search-result-sec">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="search-result-inner">
                <div className="search-result-detail">
                  <div className="col-12 col-lg-10 title">
                    <span>{contactData?.BodyContent?.SmallTitle}</span>
                    <h2>{contactData?.BodyContent?.SectionHeading}</h2>
                  <p
                    className="text-block"
                    dangerouslySetInnerHTML={{
                      __html: 
                        contactData?.BodyContent?.Content
                      ,
                    }}
                  ></p>
                  </div>
                </div>
                <div className="search-result-box">
                  <div className="row">
                    {contactData?.Employees?.map((employee) => (
                      <div className="col-lg-4 col-md-6">
                        <div className="text-box">
                          <span>{employee?.JobTitle}</span>
                          <h3>{employee?.Name}</h3>
                          <p>{employee?.PhoneNumber}</p>
                          {employee?.Enabled && <a href={`mailto:${employee?.EmailAddress}`}>Email Me</a>}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {contactData?.ShowContactForm && (
        <section className="contact-us-detail">
          <div className="container">
            <div className="row">
              <div className="col-12">
                {!dataPosted ? (
                  <div className="contact-us-detail-inner">
                    <form onSubmit={(e) => onSubmit(e)}>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="first name"
                              onChange={handleFirstName}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="last name"
                              onChange={handleLastName}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label id="email-label" htmlFor="email-input">Email must contain "@" and "." !</label>
                            <input
                              id="email-input"
                              type="email"
                              className="form-control"
                              placeholder="email"
                              onChange={handleEmail}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="phone number"
                              onChange={handlePhoneNumber}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <select
                              className="form-control"
                              onChange={handleAttention}
                            >
                              <option value="I did not select an option.">
                                  Please choose an option...
                              </option>
                              {contactData?.Employees?.map((employee) => (
                                <option value={employee?.Name}>
                                  Attention: {employee?.Name},{" "}
                                  {employee?.JobTitle}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <textarea
                              className="form-control"
                              placeholder="message"
                              onChange={handleMessage}
                            ></textarea>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group" id="captcha-Vx">
                            <ReCAPTCHA
                              sitekey="6Lc37fMUAAAAAPrvtFHE_mQS_XseLDdggKtz8dpD"
                              onChange={onCaptcha}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <a onClick={(e) => onSubmit(e)}>let’s talk</a>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                ) : (
                  <div className="contact-us-getting">
                    <div className="col-12 col-lg-10 text-box">
                      <span>{contactData?.ContactPageDetails?.ThankYouTitle}</span>
                      <p dangerouslySetInnerHTML={{
                      __html: 
                        contactData?.ContactPageDetails?.ThankYouContent
                      ,
                    }}>
                      </p>
                    </div>
                    <div className="image-holder">
                      <img
                        className="img-fluid"
                        src="img/floor-img.png"
                        alt=""
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      )}
      {/*<FamilyOwned heritageData={siteConfig} />*/}
      {siteConfig?.MainHomeFooterCarousel?.length !== 0 && <Slideshow slideData={siteConfig} />}
    </div>
  );
};

export default Contact;
