export const API_START = "API_START";
export const API_END = 'API_END';
export const API_ERROR = 'API_ERROR';

export const GET_HOME = "GET_HOME";
export const GET_ENVIRONMENT = "GET_ENVIRONMENT";
export const GET_GALLERY = "GET_GALLERY";
export const GET_GALLERY_SECONDARY = "GET_GALLERY_SECONDARY";
export const GET_DISTRIBUTORS = "GET_DISTRIBUTORS";
export const GET_ABOUT = "GET_ABOUT";
export const GET_CONTACT = "GET_CONTACT";
export const GET_SEARCH = "GET_SEARCH";
export const GET_COLLECTION = "GET_COLLECTION";
export const GET_COLLECTION_DETAIL = "GET_COLLECTION_DETAIL";
export const GET_CUSTOM_PAGE = 'GET_CUSTOM_PAGE';
export const GET_TAGS = 'GET_TAGS';

// API saga watchers
export const WATCH_HOME_PAGE = "WATCH_HOME_PAGE"
export const WATCH_ENVIRONMENT_PAGE = "WATCH_ENVIRONMENT_PAGE"
export const WATCH_GALLERY_PAGE = "WATCH_GALLERY_PAGE"
export const WATCH_GALLERY_SECONDARY_PAGE = "WATCH_GALLERY_SECONDARY_PAGE"
export const WATCH_DISTRIBUTORS_PAGE = "WATCH_DISTRIBUTORS_PAGE"
export const WATCH_ABOUT_PAGE = "WATCH_ABOUT_PAGE"
export const WATCH_CONTACT_PAGE = "WATCH_CONTACT_PAGE"
export const WATCH_SEARCH_PAGE = "WATCH_SEARCH_PAGE"
export const WATCH_COLLECTION_PAGE = "WATCH_COLLECTION_PAGE"
export const WATCH_COLLECTION_DETAIL_PAGE = "WATCH_COLLECTION_DETAIL_PAGE"
export const WATCH_CUSTOM_PAGE = "WATCH_CUSTOM_PAGE"
export const WATCH_TAGS = "WATCH_TAGS"