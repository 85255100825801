import React from "react";
import PageHeader from "./blockTypes/PageHeader";
import TextContent from "./blockTypes/TextContent";
import TextWithBackground from "./blockTypes/TextWithBackground";
import ClientsContent from "./blockTypes/ClientsContent";
import { heritagePage } from "../../models/heritagePage";

interface Props {
  blocks: heritagePage | null;
}

const HeritagePage = (props: Props) => {
  const blocks = props.blocks;

  const getBlockComponent = (block) => {

    if (block[0] === "PageHeader") {
      return <PageHeader data={block[1]} />;
    }
  };

  const getExtraComponent = (block) => {

    if (block.__component === "page-components.letter-from-president") {
      return <TextWithBackground data={block} />;
    }

    if (block.__component === "page-components.text-content") {
      return <TextContent data={block} />;
    }

    if (block.__component === "page-components.our-clients") {
      return <ClientsContent data={block} />;
    }
  };

  return (
    <div className="blocks_loop template-page">
      {blocks && (
        <>
          {Object.entries(blocks).map((block) => getBlockComponent(block))}
          {blocks.FirstContentBlock?.map((block) => getExtraComponent(block))}
        </>
      )}
    </div>
  );
};

export default HeritagePage;
