import React from "react";
import { Collection } from "../../models/collection";

const createDOMPurify = require("dompurify");
const { JSDOM } = require("jsdom");

const window = new JSDOM("").window;
const DOMPurify = createDOMPurify(window);

var Scroll = require("react-scroll");
const Element = Scroll.Element;

interface Props {
  collectionData: Collection;
}

const CollectionPackaging = (props: Props) => {
  const collectionData = props.collectionData;

  return (
    <div className="collection-packaging">
      <Element name="CollectionPagePackaging"></Element>
      <div className="heading">
        <p
          className="text-block"
          dangerouslySetInnerHTML={{
            __html: collectionData?.Standards?.Description,
          }}
        ></p>
      </div>
      <div className="collection-packaging-inner">
        <div className="row">
          <div className="col-md-6">
            <div className="collection-packaging-left">
              <div className="row">
                <h3 className="col-12 col-lg-10">{collectionData?.Standards?.WidthOptionsTitle}</h3>
              </div>
              {collectionData?.Standards?.WidthOptions?.map((width) => (
                <div className="text-box">
                  <p>{width.Size}</p>
                  <ul>
                    <li>Thickness: {width?.ThicknessOptions} </li>
                    <li>Pallet: {width?.PalletQty} {width?.PalletQty > 1 ? 'square feet' : 'square foot'}</li>
                    <li>Bundle: {width?.BundleQty}</li>
                    <li>{width?.BundlesPerPallet}</li>
                  </ul>
                </div>
              ))}
            </div>
          </div>
          <div className="col-md-6">
            <div className="collection-packaging-right">
              {collectionData?.Standards?.CollectionSpecs?.map((item) => (
                <div className="text-box">
                  <h3>{item?.Title}</h3>
                  <div className="collectionSpecs text-block"
                    dangerouslySetInnerHTML={{
                      __html: item?.TextSection,
                    }}
                  ></div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Element name="CollectionPageContentBlocks"></Element>
    </div>
  );
};

export default CollectionPackaging;
