import React, { useEffect, useState } from 'react';
import {Helmet} from "react-helmet";
import Distribution from "../../components/Distribution";
import distributionData from "../../assets/mockData/distributionPage.json"
import { connect } from 'react-redux'
import { watchGetDistributorsPage } from '../../redux/actions'
import { useInjectSaga } from "../../utils/injectSaga";
import rootSaga from "../../redux/sagas/rootSaga"

var Scroll = require("react-scroll");
var scroller = Scroll.scroller;

const DistributionPage = ({ watchGetDistributorsPage, distributionPage, searchFromHomePage }) => {
    useInjectSaga({ key: "apiReducer", saga: rootSaga });
    const [zipcode, setZipcode] = useState("")

    useEffect(() => {
        window.scrollTo(0, 0)
        //API HTTP GET
        if (searchFromHomePage) {
          setZipcode(`dist/${searchFromHomePage}`)
          watchGetDistributorsPage(`dist/${searchFromHomePage}`)
        } else {
          watchGetDistributorsPage('')
        }
    }, [])

    useEffect(() => {
        if (searchFromHomePage) {
            scroller.scrollTo("search-results", {
                duration: 800,
                delay: 50,
                smooth: true,
                offset: -10,
              });
        }
    })

    const handleZipcode = (e: any) => {
        const { value, maxLength } = e.target;
        setZipcode(value.slice(0, maxLength))
    }

    const onSearch = (e: any) => {
        e.preventDefault()
        if (zipcode !== "") {
        //api HTTP GET "url/zipcode"
        watchGetDistributorsPage(`dist/${zipcode}`)
        scroller.scrollTo("search-results", {
            duration: 800,
            delay: 50,
            smooth: true,
            offset: -10,
          });
        }      
    }
    
    return(
        <>
        <Helmet>
			<title>Distribution</title>
            <body className="distribution-page" />
		</Helmet>
        <Distribution distributionData={distributionPage} onSearch={onSearch} handleZipcode={handleZipcode} zipcode={zipcode} />
        </>
    )
}

const mapStateToProps = (state) => ({
    distributionPage: state.apiReducer.distributionPage,
    searchFromHomePage: state.router.location.state
})

export default connect(mapStateToProps, { watchGetDistributorsPage })(DistributionPage);