import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import CollectionDetail from "../../components/CollectionDetail";
import collectionData from "../../assets/mockData/collectionDetail.json";
import { connect } from "react-redux";
import { watchGetCollectionDetailPage } from "../../redux/actions";
import PropTypes from 'prop-types';

const CollectionPage = ({ slug, homepageData, collection, watchGetCollectionDetailPage }) => {
  useEffect(() => {
    //API HTTP GET
    watchGetCollectionDetailPage(slug)
  }, [watchGetCollectionDetailPage, slug, homepageData]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>Collection</title>
      </Helmet>
      <CollectionDetail
        collectionData={collection}
        siteConfig={homepageData}
      />
    </>
  );
};

CollectionPage.propTypes = {
  apiLoading: PropTypes.bool,
  homepageData: PropTypes.object.isRequired,
  collection: PropTypes.array.isRequired,
  watchGetCollectionDetailPage: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  apiLoading: state.apiReducer.apiLoading,
  homepageData: state.apiReducer.homepageData,
  collection: state.apiReducer.collection,
  slug: state.router.location.pathname.split('/')[2]
});

export default connect(mapStateToProps, { watchGetCollectionDetailPage })(
  CollectionPage
);
