import React, { useEffect, useState } from 'react';
import {Helmet} from "react-helmet";
import Heritage from "../../components/Heritage"
//import heritageData from "../../assets/mockData/heritagePage.json"
import { connect } from 'react-redux'
import { watchGetAboutPage } from "../../redux/actions"
import PropTypes from 'prop-types';
import { useInjectSaga } from "../../utils/injectSaga";
import rootSaga from "../../redux/sagas/rootSaga"

const HeritagePage = ({ watchGetAboutPage, apiLoading, heritagePage }) => {
    useInjectSaga({ key: "apiReducer", saga: rootSaga });
    const [heritageData, setHeritageData] = useState(null)

    useEffect(() => {
        //API HTTP GET
        watchGetAboutPage()
    }, [])

    useEffect(() => {
        setHeritageData(heritagePage)
    }, [setHeritageData, apiLoading, heritagePage])

    return(
        <>
        <Helmet>
			<title>Heritage</title>
		</Helmet>
        <Heritage blocks={heritageData} />
        </>
    )
}

HeritagePage.propTypes = {
    apiLoading: PropTypes.bool,
    heritagePage: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
    apiLoading: state.apiReducer.apiLoading,
    heritagePage: state.apiReducer.heritagePage,
  });

export default connect(mapStateToProps, { watchGetAboutPage })(HeritagePage);