import React from "react";

const Mobile2x1 = (props) => {
  const data = props.data;
  const setIsOpen = props.setIsOpen;
  const lightboxImages = props.lightboxImages;
  const setPhotoIndex = props.setPhotoIndex;

  return (
    <>
      <div className="col-md-4 col-6">
        <div className="image-holder small-left">
          <img
            src={!data?.TopLeft ? "" : data?.TopLeft?.url}
            alt=""
            onClick={() => {
              setIsOpen(true);
              setPhotoIndex(lightboxImages.indexOf(data.TopLeft.url));
            }}
          />
        </div>
      </div>
      <div className="col-md-4 col-6">
        <div className="image-holder small-right">
          <img
            src={!data?.BottomLeft ? "" : data?.BottomLeft[0]?.url}
            alt=""
            onClick={() => {
              setIsOpen(true);
              setPhotoIndex(lightboxImages.indexOf(data?.BottomLeft[0]?.url));
            }}
          />
        </div>
      </div>
      <div className="col-md-4 col-6">
        <div className="image-holder small-left">
          <img
            src={!data?.Right[0]?.url ? "" : data?.Right[0]?.url}
            alt=""
            onClick={() => {
              setIsOpen(true);
              setPhotoIndex(lightboxImages.indexOf(data?.Right[0]?.url));
            }}
          />
        </div>
      </div>
    </>
  );
};

export default Mobile2x1;
