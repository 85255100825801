import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import logo from "../../assets/images/logo.svg";
import searchico from "../../assets/images/icon-search.png";
import Navbar from "react-bootstrap/Navbar";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import { connect } from "react-redux";
import PropTypes from "prop-types";
var slugify = require("slugify");

var Scroll = require("react-scroll");
var scroller = Scroll.scroller;

function MobileHeader({ apiLoading, homepageData }) {
  const [productClicked, setProductClicked] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const history = useHistory();
  const [show, setShow] = useState(false);
  const navMenuData = homepageData?.NavMenu

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  console.log(homepageData);

  useEffect(() => {
    if (productClicked) {
      scroller.scrollTo("HomePageProducts", {
        duration: 800,
        delay: 50,
        smooth: true,
        offset: 0,
      });
      setProductClicked(false);
    }
  }, [setProductClicked, productClicked]);

  const handleSearchTerm = (e: any) => {
    setSearchTerm(e.target.value);
  };

  const clearSearchTerm = (e: any) => {
    setSearchTerm("");
    e.stopPropagation();
  };

  const onSearchSubmit = (e: any) => {
    if(e.keyCode == 13) {
      e.preventDefault(e);
      history.push(`/search/${slugify(searchTerm)}`);
      setSearchTerm("");
      handleClose();
      closeNav();
    }
  };

  const onSearchSubmitButton = (e: any) => {
      e.preventDefault(e);
      history.push(`/search/${slugify(searchTerm)}`);
      setSearchTerm("");
      handleClose();
      closeNav();
  };

  return (
    <header>
      <div className="container">
        <div className="top-of-nav">{homepageData?.ContactNumber}</div>
        <Navbar className="navbar" expand="lg">
          <Link className="navbar-brand" to="/">
            <img src={homepageData?.SiteLogo?.url} alt="" />
          </Link>
          <button
            className="navbar-toggler collapsed"
            aria-controls="basic-navbar-nav"
            onClick={openNav}
          >
            <span className="navbar-toggler-icon"></span>
            <span className="navbar-toggler-icon"></span>
            <span className="navbar-toggler-icon"></span>
          </button>

          <Navbar.Collapse className="overlay" id="myNav">
            <a
              href="javascript:void(0)"
              className="closebtn"
              onClick={closeNav}
            >
              &times;
            </a>
            <ul className="overlay-content navbar-nav ml-auto">
              {homepageData?.NavMenu?.map((menu, id) => ( GetMenuObject(menu, id, setProductClicked, navMenuData)))}
              <li className="nav-item">
                <Link className="nav-link" to="/contact" onClick={closeNav}>
                  Contact
                </Link>
              </li>
              <li className="nav-item">
                <Dropdown className="nav-link">
                  <Dropdown.Toggle
                    className="noCaret"
                    style={{ cursor: "pointer" }}
                    as="div"
                    id="dropdown-basic"
                    onClick={handleShow}
                  >
                    <img src={searchico} alt="" />
                  </Dropdown.Toggle>
                </Dropdown>
              </li>
            </ul>
          </Navbar.Collapse>
        </Navbar>
      </div>

      <Modal show={show} onHide={handleClose} dialogClassName="modal-custom">
        <div>
          <a className="close-search" onClick={handleClose}>
            &times;
          </a>
          <div className="search-input-container">
            <textarea id="search-input" placeholder="Type something to begin searching" onChange={handleSearchTerm} onKeyDown={onSearchSubmit}></textarea>
            <button id="search-button" onClick={onSearchSubmitButton} className="btn btn-primary">Search</button>
          </div>
        </div>
      </Modal>
    </header>
  );
}

function GetMenuObject(instance: any, id: any, productCallback: any, navMenuData: any){
  let linkPath = '';
  let menuTitle = '';

  if(instance.linkType === 'Prebuilt') {
    switch (instance.builtInPage){
      case 'About':
        linkPath = '/heritage';
        break;
      case 'Distributors':
        linkPath = '/distributors';
        break;
      case 'Gallery':
        linkPath = '/gallery';
        break;
      case 'GallerySecondary':
        linkPath = '/gallery-other';
        break;
      case 'Products':
        if (instance.ParentChild !== "Child") {
          return (
              <li className="nav-item" key={id}>
                <Link className="nav-link" to="/" onClick={() => {scrollToProducts(); closeNav()}}>{instance.menuTitle}</Link>
              </li>
          )
        }
        break;
      default:
        linkPath = '';
        menuTitle = '';
        break;
    }
  }
  else if(instance.linkType === 'CustomPage'){
    linkPath = `/page/${instance.custom_page?.slug}`;
  }
  else if(instance.linkType === 'External'){
    linkPath = instance.externalLink;
  }
  menuTitle = instance.menuTitle;
  if (menuTitle && instance.ParentChild === "Parent"){
    return(
        <li className="nav-item">
          <Dropdown className="nav-link">
            <Dropdown.Toggle style={{cursor: "pointer"}} as="div" id="dropdown-basic">
              {menuTitle}
            </Dropdown.Toggle>

            <Dropdown.Menu className="" id="menu-dropdown">
              {navMenuData?.filter(title => title.custom_dropdown?.DropdownTitle.includes(instance.custom_dropdown?.DropdownTitle)).map((item, index) =>
                  <>
                    {index !== 0 &&
                    childLinkPath(item)
                    }
                  </>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </li>
    )
  }
  if(menuTitle && linkPath && instance.ParentChild !== "Child"){
    return (
        <li className="nav-item" key={id}>
          <Link className='nav-link' to={linkPath} onClick={() => closeNav()}>{menuTitle}</Link>
        </li>
    )
  }
}

function childLinkPath(instance) {
  let linkPath
  if(instance.linkType === 'Prebuilt') {
    switch (instance.builtInPage){
      case 'About':
        linkPath = '/heritage';
        break;
      case 'Distributors':
        linkPath = '/distributors';
        break;
      case 'Gallery':
        linkPath = '/gallery';
        break;
      case 'GallerySecondary':
        linkPath = '/gallery-other';
        break;
      case 'Products':
        if (instance.ParentChild === "Child") {
          return(
              <Dropdown.Item as="form">
                <Link className="nav-link" to="/" onClick={() => {scrollToProducts(); closeNav()}}>{instance.menuTitle}</Link>
              </Dropdown.Item>)
        }
        break;
      default:
        linkPath = '';
        break;
    }
  }
  else if(instance.linkType === 'CustomPage'){
    linkPath = `/page/${instance.custom_page?.slug}`;
  }
  else if(instance.linkType === 'External'){
    linkPath = instance.externalLink;
    return(
        <Dropdown.Item as="form">
          <a className='nav-link' href={linkPath} onClick={() => closeNav()}>{instance.menuTitle}</a>
        </Dropdown.Item>
    )
  }
  return(
      <Dropdown.Item as="form">
        <Link className='nav-link' to={linkPath} onClick={() => closeNav()}>{instance.menuTitle}</Link>
      </Dropdown.Item>
  )
}

function openNav() {
  document!.getElementById("myNav")!.style.height = "100%";
}

function closeNav() {
  document!.getElementById("myNav")!.style.height = "0%";
}

const scrollToProducts = () => {
  localStorage.setItem('scroll-loc', 'HomePageProducts');
}

MobileHeader.propTypes = {
  apiLoading: PropTypes.bool,
  homepageData: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  apiLoading: state.apiReducer.apiLoading,
  homepageData: state.apiReducer.homepageData,
});

export default connect(mapStateToProps)(MobileHeader);
