import { put, takeEvery, all, call } from 'redux-saga/effects'
import * as action from "../actions"
import request from "../../utils/request"

const BASE_URL = "https://admin.maxwellhardwoodflooring.com"

// GET response for Home Page
export function* getHomePage() {
  yield put(action.apiStart())
  try {
    const data = yield call(request, `${BASE_URL}/site-configuration`)
    if(data){
      const menuData = yield call(request, `${BASE_URL}/site-menu`)
      if(menuData){
        console.log(menuData);
        data['NavMenu'] = menuData.Menu;
      }
    }
    yield put(action.getHomePage(data))
    yield put(action.apiEnd())
  } catch (error) {
    console.error(error.message, 'Server responded with error')
    yield put(action.apiError())
  }
}

// GET response for Environment Page
export function* getEnvironmentPage() {
  yield put(action.apiStart())
  try {
    const data = yield call(request, `${BASE_URL}/custom-pages`)

    yield put(action.getEnvironmentPage(data))
    yield put(action.apiEnd())
  } catch (error) {
    console.error(error.message, 'Server responded with error')
    yield put(action.apiError())
  }
}

// GET response for Gallery Page
export function* getGalleryPage() {
  yield put(action.apiStart())
  try {
    const data = yield call(request, `${BASE_URL}/gallery-page`)

    yield put(action.getGalleryPage(data))
    yield put(action.apiEnd())
  } catch (error) {
    console.error(error.message, 'Server responded with error')
    yield put(action.apiError())
  }
}

// GET response for Gallery Page
export function* getGallerySecondaryPage() {
  yield put(action.apiStart())
  try {
    const data = yield call(request, `${BASE_URL}/gallery-secondary`)

    yield put(action.getGallerySecondaryPage(data))
    yield put(action.apiEnd())
  } catch (error) {
    console.error(error.message, 'Server responded with error')
    yield put(action.apiError())
  }
}

// GET response for Distributors Page
export function* getDistributorsPage({...searchTerm}) {
  yield put(action.apiStart())
  try {
    const data = yield call(request, `${BASE_URL}/distributors/${searchTerm.searchTerm}`)

    yield put(action.getDistributorsPage(data))
    yield put(action.apiEnd())
  } catch (error) {
    console.error(error.message, 'Server responded with error')
    yield put(action.apiError())
  }
}

// GET response for Heritage Page
export function* getAboutPage() {
    yield put(action.apiStart())
    try {
      const data = yield call(request, `${BASE_URL}/about-us`)

      yield put(action.getAboutPage(data))
      yield put(action.apiEnd())
    } catch (error) {
      console.error(error.message, 'Server responded with error')
      yield put(action.apiError())
    }
}

// GET response for Contact Page
export function* getContactPage() {
  yield put(action.apiStart())
  try {
    const data = yield call(request, `${BASE_URL}/contact-page-settings`)

    yield put(action.getContactPage(data))
    yield put(action.apiEnd())
  } catch (error) {
    console.error(error.message, 'Server responded with error')
    yield put(action.apiError())
  }
}

// GET response for Search Page
export function* getSearchPage({...searchTerm}) {
  yield put(action.apiStart())
  try {
    const data = yield call(request, `${BASE_URL}/flooring-collections/?_q=${searchTerm.searchTerm}`)

    yield put(action.getSearchPage(data))
    yield put(action.apiEnd())
  } catch (error) {
    console.error(error.message, 'Server responded with error')
    yield put(action.apiError())
  }
}

// GET response for Collections Page
export function* getCollectionPage() {
  yield put(action.apiStart())
  try {
    const data = yield call(request, `${BASE_URL}/flooring-collections`)

    yield put(action.getCollectionPage(data))
    yield put(action.apiEnd())
  } catch (error) {
    console.error(error.message, 'Server responded with error')
    yield put(action.apiError())
  }
}

// GET response for Collections Page
export function* getCollectionDetailPage({...slug}) {
  yield put(action.apiStart())
  try {
    const data = yield call(request, `${BASE_URL}/flooring-collection/${slug.slug}`)

    yield put(action.getCollectionDetailPage(data))
    yield put(action.apiEnd())
  } catch (error) {
    console.error(error.message, 'Server responded with error')
    yield put(action.apiError())
  }
}
// GET response for Custom Page
export function* getCustomPage({...slug}) {
  yield put(action.apiStart())
  try {
    const data = yield call(request, `${BASE_URL}/custom-pages/${slug.slug}`)

    yield put(action.getCustomPage(data))
    yield put(action.apiEnd())
  } catch (error) {
    console.error(error.message, 'Server responded with error')
    yield put(action.apiError())
  }
}
// GET response for TAGS
export function* getTags() {
  yield put(action.apiStart())
  try {
    const data = yield call(request, `${BASE_URL}/tags`)

    yield put(action.getTags(data))
    yield put(action.apiEnd())
  } catch (error) {
    console.error(error.message, 'Server responded with error')
    yield put(action.apiError())
  }
}

// Watcher Sagas
// API call sagas
export function* watchGetHomePage() {
  yield takeEvery("WATCH_HOME_PAGE", getHomePage)
}

export function* watchGetEnvironmentPage() {
  yield takeEvery("WATCH_ENVIRONMENT_PAGE", getEnvironmentPage)
}

export function* watchGetGalleryPage() {
  yield takeEvery("WATCH_GALLERY_PAGE", getGalleryPage)
}

export function* watchGetGallerySecondaryPage() {
  yield takeEvery("WATCH_GALLERY_SECONDARY_PAGE", getGallerySecondaryPage)
}

export function* watchGetDistributorsPage() {
  yield takeEvery("WATCH_DISTRIBUTORS_PAGE", getDistributorsPage)
}

export function* watchGetAboutPage() {
  yield takeEvery("WATCH_ABOUT_PAGE", getAboutPage)
}

export function* watchGetContactPage() {
  yield takeEvery("WATCH_CONTACT_PAGE", getContactPage)
}

export function* watchGetSearchPage() {
  yield takeEvery("WATCH_SEARCH_PAGE", getSearchPage)
}

export function* watchGetCollectionPage() {
  yield takeEvery("WATCH_COLLECTION_PAGE", getCollectionPage)
}

export function* watchGetCollectionDetailPage() {
  yield takeEvery("WATCH_COLLECTION_DETAIL_PAGE", getCollectionDetailPage)
}

export function* watchGetCustomPage() {
  yield takeEvery("WATCH_CUSTOM_PAGE", getCustomPage)
}

export function* watchGetTags() {
  yield takeEvery("WATCH_TAGS", getTags)
}
