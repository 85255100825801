import React from "react";

const Gallery2x1 = (props) => {
  const data = props.data;
  const setIsOpen = props.setIsOpen;
  const lightboxImages = props.lightboxImages;
  const setPhotoIndex = props.setPhotoIndex;

  return (
    <>
      <div className="gallery-inner-box dasktop-version">
        <div className="row">
          <div className="col-md-4 col-6">
            <div className="image-holder small-left">
              <img
                src={!data.TopLeft ? "" : data.TopLeft.url}
                alt=""
                onClick={() => {
                  setIsOpen(true);
                  setPhotoIndex(lightboxImages.indexOf(data.TopLeft.url));
                }}
              />
            </div>
            <div className="image-holder small-left">
              <img
                src={!data.BottomLeft[0] ? "" : data.BottomLeft[0].url}
                alt=""
                onClick={() => {
                  setIsOpen(true);
                  setPhotoIndex(lightboxImages.indexOf(data.BottomLeft[0].url));
                }}
              />
            </div>
          </div>
          <div className="col-md-8 col-6">
            <div className="image-holder right-img">
              <img
                src={!data.Right[0] ? "" : data.Right[0].url}
                alt=""
                onClick={() => {
                  setIsOpen(true);
                  setPhotoIndex(lightboxImages.indexOf(data.Right[0].url));
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Gallery2x1;
