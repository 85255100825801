import React, { useState } from "react";
import Lightbox from "react-image-lightbox";
import LightboxCaption from "../Common/LightboxCaption";
import { Collection } from "../../models/collection";

const createDOMPurify = require("dompurify");
const { JSDOM } = require("jsdom");

const window = new JSDOM("").window;
const DOMPurify = createDOMPurify(window);

var Scroll = require("react-scroll");
var scroller = Scroll.scroller;
const Element = Scroll.Element;

const scrollMe = () => {
  scroller.scrollTo(localStorage.getItem("scroll-loc"), {
    duration: 800,
    delay: 300,
    smooth: true,
    offset: 0,
  });
  localStorage.removeItem("scroll-loc");
};

interface Props {
  collectionData: Collection;
}

const CollectionGrades = (props: Props) => {
  const collectionData = props.collectionData;
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const lightboxImages: string[] = [];
  const lightboxCaptions: any[] = [];
  const lightboxLabels: any[] = [];

  collectionData?.FlooringGrades?.ImageWithCaption?.map((grade) => {
    lightboxImages.push(grade?.GradeImage?.url);
    lightboxLabels.push(grade?.ImageLabel);
    lightboxCaptions.push(grade?.ImageCaption);
  });

  return (
    <>
      <div className="collection-grades">
        <div className="heading">
          <h3>Grades</h3>
          <p
            className="text-block"
            dangerouslySetInnerHTML={{
              __html:
                collectionData?.FlooringGrades?.Description
              ,
            }}
          ></p>
        </div>
        <div className="collection-grades-inner">
          <div className="row">
            {collectionData?.FlooringGrades?.ImageWithCaption?.map(
              (grade, index) => (
                <div className="col-md-3 col-6">
                  <div className="collection-grades-box">
                    <div className="image-holder">
                      {index === 0 ? (
                        <img
                          className="img-fluid"
                          src={grade?.GradeImage?.url}
                          alt=""
                          onLoad={scrollMe}
                          onClick={() => {
                            setIsOpen(true);
                            setPhotoIndex(
                              lightboxImages.indexOf(grade?.GradeImage?.url)
                            );
                          }}
                        />
                      ) : (
                        <img
                          className="img-fluid"
                          src={grade?.GradeImage?.url}
                          alt=""
                          onClick={() => {
                            setIsOpen(true);
                            setPhotoIndex(
                              lightboxImages.indexOf(grade?.GradeImage?.url)
                            );
                          }}
                        />
                      )}
                    </div>
                    <h3>{grade?.ImageLabel}</h3>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </div>

      {isOpen && (
        <Lightbox
          mainSrc={lightboxImages[photoIndex]}
          nextSrc={
            lightboxImages.filter(Boolean)[
              (photoIndex + 1) % lightboxImages.filter(Boolean).length
            ]
          }
          prevSrc={
            lightboxImages.filter(Boolean)[
              (photoIndex + lightboxImages.length - 1) %
                lightboxImages.filter(Boolean).length
            ]
          }
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex(
              (photoIndex + lightboxImages.filter(Boolean).length - 1) %
                lightboxImages.filter(Boolean).length
            )
          }
          onMoveNextRequest={() =>
            setPhotoIndex(
              (photoIndex + 1) % lightboxImages.filter(Boolean).length
            )
          }
          imagePadding={40}
          imageCaption={
            <LightboxCaption
              title={lightboxLabels[photoIndex]}
              desc={lightboxCaptions[photoIndex]}
            />
          }
        />
      )}
    </>
  );
};

export default CollectionGrades;
