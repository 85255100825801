import React from "react";

const createDOMPurify = require('dompurify');
const { JSDOM } = require('jsdom');

const window = new JSDOM('').window;
const DOMPurify = createDOMPurify(window);

const TextWithBackground = (props) => {
  const data = props.data;
  return (
    <section
      className="template-letter-sec"
      style={{
        background: `url(${data?.BackgroundImage?.url}) no-repeat`,
        backgroundSize: "cover",
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="text-box title">
              <span>A letter from the Chairman and CEO </span>
              <p dangerouslySetInnerHTML={{ __html: data?.Content }}></p>
              <h4>Thomas V. Maxwell</h4>
              <strong>Chairman and CEO</strong>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TextWithBackground;
