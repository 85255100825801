import React from "react";
import { useHistory } from "react-router-dom";
import { homePage } from "../../models/homePage";

const createDOMPurify = require("dompurify");
const { JSDOM } = require("jsdom");

const window = new JSDOM("").window;
const DOMPurify = createDOMPurify(window);

var Scroll = require("react-scroll");
var scroller = Scroll.scroller;

interface Props {
  heritageData: homePage;
}

const FamilyOwned = (props: Props) => {
  const heritageData = props.heritageData;
  const history = useHistory();

  const scrollMe = () => {
    if (heritageData?.HeritageSection?.ButtonLinksTo === 'ExternalPage') {
      return 1 //do nothing
    }
    if (
      heritageData
        ?.HeritageSection?.PageLink?.split("/")
        .includes("collections")
    ) {
      var linkTo = heritageData
        ?.HeritageSection?.PageLink!.split("/")
        .slice(-2)
        .join("/");
      if (heritageData?.HeritageSection?.ButtonLinksTo !== "NoScroll") {
        localStorage.setItem(
          "scroll-loc",
          heritageData?.HeritageSection?.ButtonLinksTo
        );
      }
      return history.push(linkTo);
    }
    if (
      heritageData
        ?.HeritageSection?.PageLink?.split("/")
        .includes("page")
    ) {
      var linkTo = heritageData
        ?.HeritageSection?.PageLink!.split("/")
        .slice(-2)
        .join("/");
      if (heritageData?.HeritageSection?.ButtonLinksTo !== "NoScroll") {
        localStorage.setItem(
          "scroll-loc",
          heritageData?.HeritageSection?.ButtonLinksTo
        );
      }
      return history.push(linkTo);
    }
    if (heritageData?.HeritageSection?.PageLink !== null) {
      var linkTo = heritageData
        ?.HeritageSection?.PageLink!.split("/")
        .slice(-1)[0];
      return history.push(linkTo);
    }
    scroller.scrollTo(heritageData?.HeritageSection?.ButtonLinksTo, {
      duration: 800,
      delay: 50,
      smooth: true,
      offset: 0,
    });
  };

  return (
    <section
      className="family-owned-sec"
      style={{
        backgroundImage:
          "url(" + heritageData?.HeritageSection?.BackgroundImage?.url + ")",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="family-owned-inner">
              <span>{heritageData?.HeritageSection?.Title}</span>
              <h3>{heritageData?.HeritageSection?.MiddleText}</h3>
              <p
                dangerouslySetInnerHTML={{
                  __html:
                    heritageData?.HeritageSection?.BottomText
                  ,
                }}
              ></p>
              {heritageData?.HeritageSection?.ButtonText && (
                <a
                  href={heritageData?.HeritageSection?.ButtonLinksTo === 'ExternalPage' ? heritageData?.HeritageSection?.PageLink! : '#'}
                  className="btn cta-primary"
                  onClick={() => scrollMe()}
                  target={heritageData?.HeritageSection?.ButtonLinksTo === 'ExternalPage' ? '_blank' : '_self'}
                  rel="noopener noreferrer"
                >
                  {heritageData?.HeritageSection?.ButtonText}
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FamilyOwned;
