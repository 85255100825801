import React from "react";

const createDOMPurify = require('dompurify');
const { JSDOM } = require('jsdom');

const window = new JSDOM('').window;
const DOMPurify = createDOMPurify(window);

const PageHeaderWithImageAndText = (props) => {
    const data = props.data;
    return (
        <section
            className="contact-banner"
            style={{
              backgroundImage: `url(${data?.HeaderImage?.url})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: `${data?.ImageXAxis?.toString()}% ${data?.ImageYAxis?.toString()}%`,
              backgroundSize: "cover",
            }}
        >
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="text-box">
                  <h2
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(
                            data?.HeaderText
                        ),
                      }}
                  ></h2>
                </div>
              </div>
            </div>
          </div>
        </section>
    );
};

export default PageHeaderWithImageAndText;
