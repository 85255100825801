import React, { useEffect, useState } from 'react';
import {Helmet} from "react-helmet";
import GallerySecondary from "../../components/GallerySecondary"
//import galleryData from "../../components/Gallery/mockGalleryBlock.json"
import { connect } from 'react-redux'
import { watchGetGallerySecondaryPage } from "../../redux/actions"
import PropTypes from 'prop-types';
import { useInjectSaga } from "../../utils/injectSaga";
import rootSaga from "../../redux/sagas/rootSaga"

const GallerySecondaryPage = ({ watchGetGallerySecondaryPage, apiLoading, galleryPage }) => {
    useInjectSaga({ key: "apiReducer", saga: rootSaga });
    const [galleryData, setGalleryData] = useState(null)

    useEffect(() => {
        //API HTTP GET
        watchGetGallerySecondaryPage()
    }, [watchGetGallerySecondaryPage])

    useEffect(() => {
        setGalleryData(galleryPage)
    }, [setGalleryData, apiLoading, galleryPage])
    
    return(
        <>
        <Helmet>
			<title>Gallery</title>
		</Helmet>
        <GallerySecondary blocks={galleryData} />
        </>
    )
}

GallerySecondaryPage.propTypes = {
    apiLoading: PropTypes.bool,
    galleryPage: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
    apiLoading: state.apiReducer.apiLoading,
    galleryPage: state.apiReducer.gallerySecondaryPage,
  });

export default connect(mapStateToProps, { watchGetGallerySecondaryPage })(GallerySecondaryPage);