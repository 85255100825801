import React from "react";

const createDOMPurify = require("dompurify");
const { JSDOM } = require("jsdom");

const window = new JSDOM("").window;
const DOMPurify = createDOMPurify(window);

const LeftContentSection = (props) => {
  const listData = props.data;
  console.log(listData)

  return (
    <section className="hardwood-list">
      <div className="container">
            <div className="hardwood-list-inner">
              <div className="row no-gutters">
                <div className="col-md-6">
                  <div className="image-holder">
                    <img className="img-fluid" src={listData?.Image?.url} alt="" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="text-box">
                    <div className="text-inner">
                      <h3>{listData?.Title}</h3>
                      <span>{listData?.SubTitle}</span>
                      <p
                        className="text-block"
                        dangerouslySetInnerHTML={{
                          __html: listData?.Description,
                        }}
                      ></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
      </div>
    </section>
  );
};

export default LeftContentSection;
