import React, { useState, useEffect } from "react";
import { distributionPage } from "../../models/distributionPage";
import Distributors from "../DistributorSearch";

var Scroll = require("react-scroll");
var Element = Scroll.Element;
var scroller = Scroll.scroller;

interface Props {
  distributionData: distributionPage[];
  onSearch: any;
  handleZipcode: any;
  zipcode: string;
}

const scrollMe = () => {
  scroller.scrollTo("search-results", {
    duration: 800,
    delay: 50,
    smooth: true,
    offset: -10,
  });
}

const _MapAbbrFullName = {"AZ":"Arizona","AL":"Alabama","AK":"Alaska","AR":"Arkansas","CA":"California","CO":"Colorado","CT":"Connecticut","DC":"District of Columbia","DE":"Delaware","FL":"Florida","GA":"Georgia","HI":"Hawaii","ID":"Idaho","IL":"Illinois","IN":"Indiana","IA":"Iowa","KS":"Kansas","KY":"Kentucky","LA":"Louisiana","ME":"Maine","MD":"Maryland","MA":"Massachusetts","MI":"Michigan","MN":"Minnesota","MS":"Mississippi","MO":"Missouri","MT":"Montana","NE":"Nebraska","NV":"Nevada","NH":"New Hampshire","NJ":"New Jersey","NM":"New Mexico","NY":"New York","NC":"North Carolina","ND":"North Dakota","OH":"Ohio","OK":"Oklahoma","OR":"Oregon","PA":"Pennsylvania","RI":"Rhode Island","SC":"South Carolina","SD":"South Dakota","TN":"Tennessee","TX":"Texas","UT":"Utah","VT":"Vermont","VA":"Virginia","WA":"Washington","WV":"West Virginia","WI":"Wisconsin","WY":"Wyoming","AB":"Alberta","BC":"British Columbia","MB":"Manitoba","NB":"New Brunswick","NF":"Newfoundland","NT":"Northwest Territory","NS":"Nova Scotia","NU":"Nunavut","ON":"Ontario","PE":"Prince Edward Island","QC":"Quebec","SK":"Saskatchewan","YT":"Yukon"};

function convertStateToFullName(input) {
  if(input === undefined) return input;
  var strInput = input.trim();
  var strStateToFind = strInput.toUpperCase().replace(/\ /g, '');
  var foundFullName = _MapAbbrFullName[strStateToFind];
  return foundFullName ;
}

function formatPhoneNumber(phoneNumberString) {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return null;
}

function distSorter(distributors) {
  var sorted = distributors.sort(function(a, b) {
    if (a.state.length === 2) {
      var state1 = convertStateToFullName(a.state)
    } else {
      var state1 = a.state
    }
    if (b.state.length === 2) {
      var state2 = convertStateToFullName(b.state)
    } else {
      var state2 = b.state
    }
    return (state1 < state2) ? -1 : (state1 > state2) ? 1 : 0 || (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0
  })
  return sorted
} 

const Distribution = (props: Props) => {
  const distributionData = props.distributionData?.filter(US => !["CANADA", "Canada", "canada"].includes(US.name));
  const onSearch = props.onSearch;
  const handleZipcode = props.handleZipcode;
  const zipcode = props.zipcode;
  const [searched, setSearched] = useState(false)

  const cdnList = (
    <>
      <div id="canada-dist">
        <div className="row">
            <div className="col-lg-4 col-md-6">
              <div id="inner-canada-dist">
                <div>
                  <h6>Canadian Distributor Information:</h6>
                  <p>Finmac Lumber Limited</p>
                  <ul>
                    <li>945 Elgin Ave</li>
                    <li>Winnipeg, Manitoba</li>
                    <li>Phone: (204) 786-7694</li>
                  </ul>
                  <a href={"http://www.finmaclumber.com/"} target="_blank">
                  View Website
                  </a>
                </div>
              </div>
            </div>
        </div>
      </div>
    </>
    )

  return (
    <>
      <section className="distribution-banner">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="text-box">
                <div className="row">
                  <h2 className="col-12 col-lg-10" id="dist-title">
                    Find a Maxwell Distributor near you.
                  </h2>
                </div>
                <form onSubmit={(e) => {onSearch(e); setSearched(true)}}>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="zip code"
                    value={zipcode}
                    onChange={handleZipcode}
                    maxLength={5}
                  />
                  <a href="#" onClick={(e) => {onSearch(e); setSearched(true)}}>
                    find distributors
                  </a>
                </form>
                {cdnList}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Element name="search-results"></Element>
      {zipcode !== "" &&
      <section className="search-result-sec">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="distribution-inner">
                <div className="search-result-detail">
                  <div className="title">
                    {distributionData?.length >= 1 && (
                      <span>
                        {zipcode !== '' && `distributors located within 200 miles of ${zipcode}`}
                      </span>
                    )}
                    <h2>Search Results</h2>
                  </div>
                </div>
                <div className="search-result-box">
                  <div className="row">
                    {distributionData && distSorter(distributionData).map((res) => (
                      <div className="col-lg-4 col-md-6">
                        <div className="text-box">
                          <span>{res?.longState}</span>
                          <h3>{res?.name}</h3>
                          <ul>
                            <li>{res?.address}</li>
                            <li>{`${res?.city}, ${res?.state}  ${res?.zip}`}</li>
                            <li>Phone: {formatPhoneNumber(res?.phoneNumber)}</li>
                          </ul>
                          {zipcode !== '' &&
                          <p>
                            distance:{" "}
                            {res?.dist === 0 ? res?.dist : res?.dist?.toFixed(1)}{" "}
                            miles
                          </p>}
                          <a href={res.url} target="_blank">
                          {res.url && 'View Website'}
                          </a>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>}
    </>
  );
};

export default Distribution;
