import React, { useEffect } from 'react';
import {Helmet} from "react-helmet";
import Contact from "../../components/Contact";
import { connect } from 'react-redux';
import { watchGetContactPage } from '../../redux/actions';
import { useInjectSaga } from "../../utils/injectSaga";
import rootSaga from "../../redux/sagas/rootSaga"

const ContactPage = ({ watchGetContactPage, siteConfig, contactData }) => {
    useInjectSaga({ key: "apiReducer", saga: rootSaga });

    useEffect(() => {
        watchGetContactPage()
    }, [])

    return(
        <>
        <Helmet>
			<title>Contact Us</title>
		</Helmet>
        <Contact siteConfig={siteConfig} contactData={contactData} />
        </>
    )
}

const mapStateToProps = state => ({
    siteConfig: state.apiReducer.homepageData,
    contactData: state.apiReducer.contactPage
})

export default connect(mapStateToProps, { watchGetContactPage })(ContactPage);
