import React from "react";

const TextContent = (props) => {
  const data = props.data;
  return (
    <section className="tempate-qualtiy">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="text-box title">
              <span>{data?.SmallTitle}</span>
              <h3>{data?.SectionHeading}</h3>
            </div>
            <div
              className="text-block"
              dangerouslySetInnerHTML={{
                __html: data?.Content,
              }}
            ></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TextContent;
