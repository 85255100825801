import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import logo from "../../assets/images/logo.svg";
import searchico from "../../assets/images/icon-search.png";
import Navbar from "react-bootstrap/Navbar";
import Dropdown from "react-bootstrap/Dropdown";
import { connect } from "react-redux";
import PropTypes from "prop-types";
var slugify = require("slugify");

var Scroll = require("react-scroll");
var scroller = Scroll.scroller;

function Header({ apiLoading, homepageData }) {
  const [productClicked, setProductClicked] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const history = useHistory();
  const navMenuData = homepageData?.NavMenu
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    if (productClicked) {
      scroller.scrollTo("HomePageProducts", {
        duration: 800,
        delay: 50,
        smooth: true,
        offset: 0,
      });
      setProductClicked(false);
    }
  }, [setProductClicked, productClicked]);

  const handleSearchTerm = (e: any) => {
    setSearchTerm(e.target.value);
  };

  const clearSearchTerm = (e: any) => {
    setSearchTerm("");
    e.stopPropagation();
  };

  const onSearchSubmit = (e: any) => {
    e.preventDefault(e);
    history.push(`/search/${slugify(searchTerm)}`);
    setSearchTerm("");
    const toggleMe = document.getElementById("search-dropdown") as HTMLElement;
    toggleMe.classList.remove("show");
    //API HTTP GET from searchTerm if placed in state, or
    // we can call from search results page from url params:searchterm
  };

  return (
    <header>
      <div className="container">
        <Navbar className="navbar" expand="lg">
          <Link className="navbar-brand" to="/">
            <img src={homepageData?.SiteLogo?.url} alt="" />
          </Link>
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <span className="navbar-toggler-icon"></span>
            <span className="navbar-toggler-icon"></span>
            <span className="navbar-toggler-icon"></span>
          </Navbar.Toggle>

          <Navbar.Collapse id="basic-navbar-nav">
            <ul className="contact-nav">
              <li>
                <Link id="contact-number" to="/contact">
                  {homepageData?.ContactNumber}
                </Link>
              </li>
              <li>
                <Link to="/contact">&nbsp;&nbsp;/&nbsp;&nbsp;CONTACT</Link>
              </li>
            </ul>
            <ul className="navbar-nav ml-auto">
              {homepageData?.NavMenu?.map((menu, id) => ( GetMenuObject(menu, id, setProductClicked, navMenuData, showDropdown, setShowDropdown)))}
              <li className="nav-item">
                  <Dropdown className="nav-link">
                    <Dropdown.Toggle className="noCaret" style={{cursor: "pointer"}} as="div" id="dropdown-basic">
                      <img src={searchico} alt="" />
                    </Dropdown.Toggle>
                  
                    <Dropdown.Menu alignRight={true} id="search-dropdown">
                      <Dropdown.Item as="form" onSubmit={(e) => onSearchSubmit(e)}>
                          <input id="search-dropdown-input" onClick={function(e) {e.stopPropagation()}} value={searchTerm} onChange={handleSearchTerm} placeholder="Search" />
                          <span id="searchclear" onClick={(e) => clearSearchTerm(e)}>x</span>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
              </li>


            </ul>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </header>
  );
}

function GetMenuObject(instance: any, id: any, productCallback: any, navMenuData: any, showDropdown: any, setShowDropdown: any){
  let linkPath = '';
  let menuTitle = '';

  const dropOpen = (item) => {
    setShowDropdown(item)
    console.log(item)
  }
  
  const dropClose = (item) => {
    setShowDropdown(false)
  }

  if(instance.linkType === 'Prebuilt') {
    switch (instance.builtInPage){
      case 'About':
        linkPath = '/heritage';
        break;
      case 'Distributors':
        linkPath = '/distributors';
        break;
      case 'Gallery':
        linkPath = '/gallery';
        break;
      case 'GallerySecondary':
        linkPath = '/gallery-other';
        break;
      case 'Products':
        if (instance.ParentChild !== "Child") {
          return (
              <li className="nav-item" key={id}>
                <Link className="nav-link" to="/" onClick={() => scrollToProducts()}>{instance.menuTitle}</Link>
              </li>
          )
        }
      break;
      default:
        linkPath = '';
        menuTitle = '';
      break;
    }
  }
  else if(instance.linkType === 'CustomPage'){
    linkPath = `/page/${instance.custom_page?.slug}`;
  }
  else if(instance.linkType === 'External'){
    linkPath = instance.externalLink;
  }
  menuTitle = instance.menuTitle;
  if (menuTitle && instance.ParentChild === "Parent"){
    return(
        <li className="nav-item">
          <Dropdown className="nav-link"
            onMouseEnter={() => dropOpen(menuTitle)}
            onMouseLeave={() => dropClose(menuTitle)}
            show={showDropdown === menuTitle}
          >
            <Dropdown.Toggle className="noCaret" style={{cursor: "pointer"}} as="div" id="dropdown-basic">
              {menuTitle}
            </Dropdown.Toggle>

            <Dropdown.Menu className="menu-dropdown-center" id="menu-dropdown">
              {navMenuData?.filter(title => title.custom_dropdown?.DropdownTitle.includes(instance.custom_dropdown?.DropdownTitle)).map((item, index) =>
                  <>
                      {index !== 0 &&
                          childLinkPath(item)
                      }
                  </>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </li>
    )
  }
  if(menuTitle && linkPath && instance.ParentChild !== "Child"){
    return (
        <li className="nav-item" key={id}>
          <Link className='nav-link' to={linkPath}>{menuTitle}</Link>
        </li>
    )
  }
}

function childLinkPath(instance) {
  let linkPath
  if(instance.linkType === 'Prebuilt') {
    switch (instance.builtInPage){
      case 'About':
        linkPath = '/heritage';
        break;
      case 'Distributors':
        linkPath = '/distributors';
        break;
      case 'Gallery':
        linkPath = '/gallery';
        break;
      case 'GallerySecondary':
        linkPath = '/gallery-other';
        break;
      case 'Products':
        if (instance.ParentChild === "Child") {
          return(
            <Dropdown.Item as="form">
              <Link className="nav-link" to="/" onClick={() => scrollToProducts()}>{instance.menuTitle}</Link>
            </Dropdown.Item>)
        }
      break;
      default:
        linkPath = '';
        break;
    }
  }
  else if(instance.linkType === 'CustomPage'){
    linkPath = `/page/${instance.custom_page?.slug}`;
  }
  else if(instance.linkType === 'External'){
    linkPath = instance.externalLink;
    return(
      <Dropdown.Item as="form">
        <a className='nav-link' href={linkPath}>{instance.menuTitle}</a>
      </Dropdown.Item>
    )
  }
  return(
      <Dropdown.Item as="form">
        <Link className='nav-link' to={linkPath}>{instance.menuTitle}</Link>
      </Dropdown.Item>
  )
}

const scrollToProducts = () => {
  localStorage.setItem('scroll-loc', 'HomePageProducts');
}

Header.propTypes = {
  apiLoading: PropTypes.bool,
  homepageData: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  apiLoading: state.apiReducer.apiLoading,
  homepageData: state.apiReducer.homepageData,
});

export default connect(mapStateToProps)(Header);
