import React, { useEffect } from 'react';
import { connect } from 'react-redux'
import { watchGetEnvironmentPage } from "../../redux/actions"
import { useInjectSaga } from "../../utils/injectSaga";
import rootSaga from "../../redux/sagas/rootSaga"

const EnvironmentPage = ({ watchGetEnvironmentPage }) => {
    useInjectSaga({ key: "apiReducer", saga: rootSaga });

    useEffect(() => {
    //API HTTP GET
    watchGetEnvironmentPage()
    }, [])

    return(
        <div style={{padding: "300px", textAlign:"center", fontSize:"40px"}}>

    </div>
    )
}

export default connect(null, { watchGetEnvironmentPage })(EnvironmentPage);
