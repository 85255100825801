import React, { useState, useEffect } from "react";
import Lightbox from "react-image-lightbox";
import LightboxCaption from "../Common/LightboxCaption";
import Carousel from "react-bootstrap/Carousel";
import prevArrow from "../../assets/images/previous-arrow.svg";
import NextArrow from "../../assets/images/next-arrow.svg";
import { collectionDetail } from "../../models/collectionDetail";
import { Collection } from "../../models/collection";
import $ from "jquery"

const createDOMPurify = require("dompurify");
const { JSDOM } = require("jsdom");

const window = new JSDOM("").window;
const DOMPurify = createDOMPurify(window);

var Scroll = require("react-scroll");
var scroller = Scroll.scroller;
const Element = Scroll.Element;

interface Props {
  collectionData: Collection;
}

const CollectionCarousel = (props: Props) => {
  const collectionData = props.collectionData;
  const [slideIndex, setSlideIndex] = useState(0);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const lightboxImages: string[] = [];
  const lightboxCaptions: string[] = [];
  const lightboxLabels: string[] = [];

  collectionData?.GalleryImages?.map((img) => {
    if (img?.GalleryImage?.url[0] !== 'h') {
      lightboxImages.push('https://' + img?.GalleryImage?.url);
      lightboxLabels.push(img?.Label);
      lightboxCaptions.push(img?.Caption);
    } else {
    lightboxImages.push(img?.GalleryImage?.url);
    lightboxLabels.push(img?.Label);
    lightboxCaptions.push(img?.Caption);
    }
  });

  const handleSelect = (selectedIndex, e) => {
    setSlideIndex(selectedIndex);
  };

  const carouselLength = lightboxImages.filter(Boolean).length;

  const nextSlide = () => {
    const currentSlide = Array.from(
      new Array(carouselLength),
      (x, i) => i
    ).indexOf(slideIndex);
    if (carouselLength === 1) {
      return; //
    }
    if (
      Array.from(new Array(carouselLength), (x, i) => i).includes(
        currentSlide + 1
      )
    ) {
      setSlideIndex(currentSlide + 1);
    } else {
      setSlideIndex(0);
    }
  };

  const prevSlide = () => {
    const currentSlide = Array.from(
      new Array(carouselLength),
      (x, i) => i
    ).indexOf(slideIndex);
    if (carouselLength === 1) {
      return; //
    }
    if (
      Array.from(new Array(carouselLength), (x, i) => i).includes(
        currentSlide - 1
      )
    ) {
      setSlideIndex(currentSlide - 1);
    } else {
      setSlideIndex(carouselLength! - 1);
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <div className="collection-detail-slide">
            <Carousel
              id="carousel-thumb"
              bsPrefix="carousel-thumbnails"
              interval={null}
              activeIndex={slideIndex}
              onSelect={handleSelect}
            >
              <Carousel
                interval={null}
                activeIndex={slideIndex}
                onSelect={handleSelect}
              >
                {lightboxImages.filter(Boolean).map((img) => (
                  <Carousel.Item bsPrefix="carousel-item">
                    <img
                      className="d-block w-100"
                      src={img}
                      alt="First slide"
                      onClick={() => {
                        setIsOpen(true);
                        setPhotoIndex(lightboxImages.indexOf(img));
                      }}
                    />
                  </Carousel.Item>
                ))}
              </Carousel>
              <div className="d-flex justify-content-center collection-gallery-arrows">
                <a
                  className="carousel-control-prev"
                  href="#carousel-thumb"
                  role="button"
                  data-slide="prev"
                  onClick={prevSlide}
                >
                  <span
                    className="carousel-control-prev-icon"
                    aria-hidden="true"
                  >
                    <img src={prevArrow} />
                  </span>
                  <span className="sr-only">Previous</span>
                </a>
                <a
                  className="carousel-control-next"
                  href="#carousel-thumb"
                  role="button"
                  data-slide="next"
                  onClick={nextSlide}
                >
                  <span
                    className="carousel-control-next-icon"
                    aria-hidden="true"
                  >
                    <img src={NextArrow} />
                  </span>
                  <span className="sr-only">Next</span>
                </a>
              </div>
              <ol className="carousel-indicators">
                {lightboxImages.filter(Boolean).map((img, index) => (
                  <li
                    data-target="#carousel-thumb"
                    data-slide-to="0"
                    className={slideIndex === index ? "active" : ""}
                  >
                    <img
                      className="d-block carousel-thumbs img-fluid"
                      src={img}
                      alt=""
                      onClick={() => setSlideIndex(index)}
                    />
                  </li>
                ))}
              </ol>
            </Carousel>
          </div>
        </div>
        <div className="col-md-6">
          <div className="text-plank">
            <span>{collectionData?.CollectionTitle}</span>
            <h3>{collectionData?.CollectionName}</h3>
            <h4>description</h4>
            <p
              className="text-block"
              dangerouslySetInnerHTML={{
                __html: collectionData?.Description,
              }}
            ></p>
          </div>
        </div>
      </div>

      {isOpen && (
        <Lightbox
          mainSrc={lightboxImages[photoIndex]}
          nextSrc={
            lightboxImages.filter(Boolean)[
              (photoIndex + 1) % lightboxImages.filter(Boolean).length
            ]
          }
          prevSrc={
            lightboxImages.filter(Boolean)[
              (photoIndex + lightboxImages.length - 1) %
                lightboxImages.filter(Boolean).length
            ]
          }
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex(
              (photoIndex + lightboxImages.filter(Boolean).length - 1) %
                lightboxImages.filter(Boolean).length
            )
          }
          onMoveNextRequest={() =>
            setPhotoIndex(
              (photoIndex + 1) % lightboxImages.filter(Boolean).length
            )
          }
          imagePadding={40}
          imageCaption={
            <LightboxCaption
              title={lightboxLabels[photoIndex]}
              desc={lightboxCaptions[photoIndex]}
            />
          }
        />
      )}
      <Element name="CollectionPageGrades"></Element>
    </>
  );
};

export default CollectionCarousel;
