import React from "react";
import { useHistory } from "react-router-dom";

const createDOMPurify = require("dompurify");
const { JSDOM } = require("jsdom");

const window = new JSDOM("").window;
const DOMPurify = createDOMPurify(window);

var Scroll = require("react-scroll");
var scroller = Scroll.scroller;

const TextWithBackground = (props) => {
  const heritageData = props.data;
  const history = useHistory();

  console.log(heritageData
    ?.PageLink?.split("/")[2])

  console.log()

  const scrollMe = () => {
    //debugger;
    if (heritageData?.ButtonLinksTo === 'ExternalPage') {
      return 1 //do nothing
    }
    if (
      heritageData
        ?.PageLink?.split("/")
        .includes("collections")
    ) {
      var linkTo = heritageData
        ?.PageLink!.split("/")
        .slice(-2)
        .join("/");
      if (heritageData?.ButtonLinksTo !== "NoScroll") {
        localStorage.setItem(
          "scroll-loc",
          heritageData?.ButtonLinksTo
        );
      }
      return history.push('/' + linkTo);
    }
    if (
      heritageData
        ?.PageLink?.split("/")
        .includes("page")
    ) {
      var linkTo = heritageData
        ?.PageLink!.split("/")
        .slice(-2)
        .join("/");
      if (heritageData?.ButtonLinksTo !== "NoScroll") {
        localStorage.setItem(
          "scroll-loc",
          heritageData?.ButtonLinksTo
        );
      }
      return history.push('/' + linkTo);
    }
    if (heritageData
      ?.PageLink?.split("/")[2] === document.documentURI.split("/")[2] && heritageData?.ButtonLinksTo !== 'NoScroll') {
        localStorage.setItem(
          "scroll-loc",
          heritageData?.ButtonLinksTo
        );
        return history.push('/')
    }
    
    if (heritageData?.PageLink !== null) {
      var linkTo = heritageData
        ?.PageLink!.split("/")
        .slice(-1)[0];
        localStorage.setItem(
          "scroll-loc",
          heritageData?.ButtonLinksTo
        );
      return history.push('/' + linkTo);
    }
    scroller.scrollTo(heritageData?.ButtonLinksTo, {
      duration: 800,
      delay: 50,
      smooth: true,
      offset: 0,
    });
  };

  return (
    <section
      className="family-owned-sec"
      style={{
        backgroundImage:
          "url(" + heritageData?.BackgroundImage?.url + ")",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="family-owned-inner">
              <span>{heritageData?.Title}</span>
              <h3>{heritageData?.MiddleText}</h3>
              <p
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(
                    heritageData?.BottomText
                  ),
                }}
              ></p>
              {heritageData?.ButtonText &&
              <div>
              {heritageData?.ButtonText && heritageData?.ButtonLinksTo === 'ExternalPage' ? (
                <a
                  href={heritageData?.ButtonLinksTo === 'ExternalPage' ? heritageData?.PageLink! : '#'}
                  className="btn cta-primary"
                  onClick={() => scrollMe()}
                  target={heritageData?.ButtonLinksTo === 'ExternalPage' ? '_blank' : ''}
                  rel="noopener noreferrer"
                >
                  {heritageData?.ButtonText}
                </a>
              ) :
              <a
                  className="btn cta-primary"
                  onClick={() => scrollMe()}
                >
                  {heritageData?.ButtonText}
                </a>
              }
              </div>}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TextWithBackground;
