import React, { useEffect, useState } from "react";
import SearchResults from "../../components/SearchResults";
import FamilyOwned from "../../components/Common/FamilyOwned";
import Slideshow from "../../components/Common/Slideshow";
import searchData from "../../assets/mockData/searchResults.json";
import { connect } from "react-redux";
import { watchGetSearchPage } from "../../redux/actions";
import { watchGetTags } from "../../redux/actions";
import { useInjectSaga } from "../../utils/injectSaga";
import rootSaga from "../../redux/sagas/rootSaga"

const SearchResultsPage = ({ watchGetSearchPage, watchGetTags, searchTerm, searchData, siteConfig , tags}) => {
  useInjectSaga({ key: "apiReducer", saga: rootSaga });
  useEffect(() => {
    //API HTTP GET
    watchGetSearchPage(searchTerm)
    watchGetTags()
  }, [watchGetSearchPage, searchTerm]);

  return (
    <>
      <SearchResults searchData={searchData} searchTerm={searchTerm} tags={tags} />
      <FamilyOwned heritageData={siteConfig} />
      <Slideshow />
    </>
  );
};

const mapStateToProps = (state) => ({
  apiLoading: state.apiReducer.apiLoading,
  searchData: state.apiReducer.searchPage,
  siteConfig: state.apiReducer.siteConfig,
  tags: state.apiReducer.tags,
  searchTerm: state.router.location.pathname.split('/')[2]
});

export default connect(mapStateToProps, { watchGetSearchPage, watchGetTags })(
  SearchResultsPage
);
