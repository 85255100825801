import React from "react";

const Mobile1x2 = (props) => {
  const data = props.data;
  const setIsOpen = props.setIsOpen;
  const lightboxImages = props.lightboxImages;
  const setPhotoIndex = props.setPhotoIndex;

  return (
    <>
      <div className="col-md-4 col-6">
        <div className="image-holder small-right">
          <img
            src={!data.Left ? "" : data.Left.url}
            alt=""
            onClick={() => {
              setIsOpen(true);
              setPhotoIndex(lightboxImages.indexOf(data.Left.url));
            }}
          />
        </div>
      </div>
      <div className="col-md-4 col-6">
        <div className="image-holder small-left">
          <img
            src={!data.TopRight ? "" : data.TopRight.url}
            alt=""
            onClick={() => {
              setIsOpen(true);
              setPhotoIndex(lightboxImages.indexOf(data.TopRight.url));
            }}
          />
        </div>
      </div>
      <div className="col-md-4 col-6">
        <div className="image-holder small-right">
          <img
            src={!data.BottomRight ? "" : data.BottomRight.url}
            alt=""
            onClick={() => {
              setIsOpen(true);
              setPhotoIndex(lightboxImages.indexOf(data.BottomRight.url));
            }}
          />
        </div>
      </div>
    </>
  );
};

export default Mobile1x2;
