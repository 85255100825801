import React from "react";

const createDOMPurify = require('dompurify');
const { JSDOM } = require('jsdom');

const window = new JSDOM('').window;
const DOMPurify = createDOMPurify(window);

const PageHeader = (props) => {
  const data = props.data;
  return (
    <section
      className="distribution-banner"
      style={{
        backgroundImage: `url(${data?.HeaderImage?.url})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: `${data?.ImageXAxis?.toString()}% ${data?.ImageYAxis?.toString()}%`,
        backgroundSize: "cover",
      }}
      id="heritage-img"
    >
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-10">
            <div className="text-box dasktop-version">
              <h2 dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data?.HeaderText) }}></h2>
            </div>
            <div className="text-box mobile-version">
              <h2 dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data?.HeaderText) }}></h2>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PageHeader;
