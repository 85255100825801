import React, { useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import { useInjectSaga } from "../../utils/injectSaga";
import rootSaga from "../../redux/sagas/rootSaga";
import { watchGetHomePage } from "../../redux/actions";
import { useMediaQuery } from "react-responsive";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import MobileHeader from "../../components/HeaderMobile";

import HomePage from "../HomePage/Loadable";
import CollectionPage from "../CollectionPage";
import EnvironmentPage from "../EnvironmentPage";
import GalleryPage from "../GalleryPage";
import GallerySecondaryPage from "../GallerySecondaryPage";
import DistributionPage from "../DistributionPage";
import HeritagePage from "../HeritagePage";
import ContactPage from "../ContactPage";
import SearchResultsPage from "../SearchResultsPage";

import GlobalStyle from "../../global-styles";
import CustomPage from "../CustomPage";
function App({ watchGetHomePage }) {
  useInjectSaga({ key: "apiReducer", saga: rootSaga });
  useEffect(() => {
    //HTTP GET API
    watchGetHomePage();
  });

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 991px)" });

  return (
    <div>
      {!isTabletOrMobile ? <Header /> : <MobileHeader />}
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route exact path="/collections/:id" component={CollectionPage} />
        <Route exact path="/page/:id" component={CustomPage} />
        <Route exact path="/environment" component={EnvironmentPage} />
        <Route exact path="/gallery" component={GalleryPage} />
        <Route exact path="/gallery-other" component={GallerySecondaryPage} />
        <Route exact path="/distributors" component={DistributionPage} />
        <Route exact path="/heritage" component={HeritagePage} />
        <Route exact path="/contact" component={ContactPage} />
        <Route exact path="/search/:query" component={SearchResultsPage} />
      </Switch>
      <Footer />
      <GlobalStyle />
    </div>
  );
}

export default connect(null, { watchGetHomePage })(App);
