import React from "react";
import { Link, useHistory } from "react-router-dom";
import CollectionCarousel from "./collectionCarousel";
import CollectionGrades from "./collectionGrades";
import CollectionPackaging from "./collectionPackaging";
import CollectionGradeDetail from "./collectionGradeDetail";
import CollectionSlideshow from "./collectionSlideshow";
import collectionLogo from "../../assets/images/collection-logo.png";
import { Collection } from "../../models/collection";
import { homePage } from "../../models/homePage";

interface Props {
  collectionData: Collection;
  siteConfig: homePage;
}

const CollectionDetail = (props: Props) => {
  const collectionData = props.collectionData;
  const siteConfig = props.siteConfig;
  const history = useHistory()

  const scrollToProducts = () => {
    localStorage.setItem('scroll-loc', 'HomePageProducts');
    history.push('/')
  }

  return (
    <>
      <section className="collection-detail-sec">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="collection-breadcrumb">
                <img
                  className="img-fluid collection-top-logo"
                  src={collectionData?.CollectionsTopLogo?.url}
                  alt=""
                />
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home </Link>
                    </li>
                    <li className="breadcrumb-item" onClick={() => scrollToProducts()}>
                      <a href="#">collections </a>
                    </li>
                    <li className="breadcrumb-item active">{collectionData?.CollectionTitle}</li>
                  </ol>
                </nav>
              </div>
              <CollectionCarousel collectionData={collectionData} />
              <CollectionGrades collectionData={collectionData} />
              <CollectionPackaging collectionData={collectionData} />
            </div>
          </div>
        </div>
      </section>
      {collectionData?.ContentSections === undefined || collectionData?.ContentSections?.length == 0 ?
      null :
      <CollectionGradeDetail collectionData={collectionData} />}
      <CollectionSlideshow
        collectionData={collectionData}
        siteConfig={siteConfig}
      />
    </>
  );
};

export default CollectionDetail;
