import React, { useState } from "react";
import { Link } from "react-router-dom";
import Lightbox from "react-image-lightbox";
import LightboxCaption from "../Common/LightboxCaption";
import Gallery1x2 from "./blockTypes/Gallery1x2";
import Gallery2x1 from "./blockTypes/Gallery2x1";
import Gallery2x3 from "./blockTypes/Gallery2x3";
import Mobile1x2 from "./blockTypes/Mobile1x2";
import Mobile2x1 from "./blockTypes/Mobile2x1";
import Mobile2x3 from "./blockTypes/Mobile2x3";
import { galleryPage } from "../../models/galleryPage";

const createDOMPurify = require("dompurify");
const { JSDOM } = require("jsdom");

const window = new JSDOM("").window;
const DOMPurify = createDOMPurify(window);

interface Props {
  blocks: galleryPage | null;
}

const GalleryPage = (props: Props) => {
  const blocks = props.blocks;
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const preFilter: string[] = []
  var lightboxImages: string[] = [];
  var lightboxLabels: string[] = [];
  var lightboxCaptions: string[] = [];

  const getComponent = (block) => {

    if (block.__component === "photo-gallery.gallery-2x1") {
      lightboxImages.push(block?.TopLeft?.url);
      lightboxLabels.push(block?.TopLeftLabel)
      lightboxCaptions.push(block?.TopLeftCaption)
      lightboxImages.push(block?.Right[0]?.url);
      lightboxLabels.push(block?.RightLabel)
      lightboxCaptions.push(block?.RightCaption)
      lightboxImages.push(block?.BottomLeft[0]?.url);
      lightboxLabels.push(block?.BottomLeftLabel)
      lightboxCaptions.push(block?.BottomLeftCaption)
      return (
        <Gallery2x1
          data={block}
          setIsOpen={setIsOpen}
          setPhotoIndex={setPhotoIndex}
          lightboxImages={lightboxImages}
        />
      );
    }

    if (block.__component === "photo-gallery.gallery-1x2") {
      lightboxImages.push(block?.Left?.url);
      lightboxLabels.push(block?.LeftLabel)
      lightboxCaptions.push(block?.LeftCaption)
      lightboxImages.push(block?.TopRight?.url);
      lightboxLabels.push(block?.TopRightLabel)
      lightboxCaptions.push(block?.TopRightCaption)
      lightboxImages.push(block?.BottomRight?.url);
      lightboxLabels.push(block?.BottomRightLabel)
      lightboxCaptions.push(block?.BottomRightCaption)
      return (
        <Gallery1x2
          data={block}
          setIsOpen={setIsOpen}
          setPhotoIndex={setPhotoIndex}
          lightboxImages={lightboxImages}
        />
      );
    }

    if (block.__component === "photo-gallery.gallery-2x3") {
      lightboxImages.push(block?.TopLeft?.url);
      lightboxLabels.push(block?.TopLeftLabel)
      lightboxCaptions.push(block?.TopLeftCaption)
      lightboxImages.push(block?.TopMiddle?.url);
      lightboxLabels.push(block?.TopMiddleLabel)
      lightboxCaptions.push(block?.TopMiddleCaption)
      lightboxImages.push(block?.TopRight?.url);
      lightboxLabels.push(block?.TopRightLabel)
      lightboxCaptions.push(block?.TopRightCaption)
      lightboxImages.push(block?.BottomLeft?.url);
      lightboxLabels.push(block?.BottomLeftLabel)
      lightboxCaptions.push(block?.BottomLeftCaption)
      lightboxImages.push(block?.BottomMiddle?.url);
      lightboxLabels.push(block?.BottomMiddleLabel)
      lightboxCaptions.push(block?.BottomMiddleCaption)
      lightboxImages.push(block?.BottomRight?.url);
      lightboxLabels.push(block?.BottomRightLabel)
      lightboxCaptions.push(block?.BottomRightCaption)
      return (
        <Gallery2x3
          data={block}
          setIsOpen={setIsOpen}
          setPhotoIndex={setPhotoIndex}
          lightboxImages={lightboxImages}
        />
      );
    }
  };

  const getMobileComponent = (block) => {

    if (block.__component === "photo-gallery.gallery-2x1") {
      lightboxImages.push(block?.TopLeft?.url);
      lightboxLabels.push(block?.TopLeftLabel)
      lightboxCaptions.push(block?.TopLeftCaption)
      lightboxImages.push(block?.Right[0]?.url);
      lightboxLabels.push(block?.RightLabel)
      lightboxCaptions.push(block?.RightCaption)
      lightboxImages.push(block?.BottomLeft[0]?.url);
      lightboxLabels.push(block?.BottomLeftLabel)
      lightboxCaptions.push(block?.BottomLeftCaption)
      return (
        <Mobile2x1
          data={block}
          setIsOpen={setIsOpen}
          setPhotoIndex={setPhotoIndex}
          lightboxImages={lightboxImages}
        />
      );
    }

    if (block.__component === "photo-gallery.gallery-1x2") {
      lightboxImages.push(block?.Left?.url);
      lightboxLabels.push(block?.LeftLabel)
      lightboxCaptions.push(block?.LeftCaption)
      lightboxImages.push(block?.TopRight?.url);
      lightboxLabels.push(block?.TopRightLabel)
      lightboxCaptions.push(block?.TopRightCaption)
      lightboxImages.push(block?.BottomRight?.url);
      lightboxLabels.push(block?.BottomRightLabel)
      lightboxCaptions.push(block?.BottomRightCaption)
      return (
        <Mobile1x2
          data={block}
          setIsOpen={setIsOpen}
          setPhotoIndex={setPhotoIndex}
          lightboxImages={lightboxImages}
        />
      );
    }

    if (block.__component === "photo-gallery.gallery-2x3") {
      lightboxImages.push(block?.TopLeft?.url);
      lightboxLabels.push(block?.TopLeftLabel)
      lightboxCaptions.push(block?.TopLeftCaption)
      lightboxImages.push(block?.TopMiddle?.url);
      lightboxLabels.push(block?.TopMiddleLabel)
      lightboxCaptions.push(block?.TopMiddleCaption)
      lightboxImages.push(block?.TopRight?.url);
      lightboxLabels.push(block?.TopRightLabel)
      lightboxCaptions.push(block?.TopRightCaption)
      lightboxImages.push(block?.BottomLeft?.url);
      lightboxLabels.push(block?.BottomLeftLabel)
      lightboxCaptions.push(block?.BottomLeftCaption)
      lightboxImages.push(block?.BottomMiddle?.url);
      lightboxLabels.push(block?.BottomMiddleLabel)
      lightboxCaptions.push(block?.BottomMiddleCaption)
      lightboxImages.push(block?.BottomRight?.url);
      lightboxLabels.push(block?.BottomRightLabel)
      lightboxCaptions.push(block?.BottomRightCaption)
      return (
        <Mobile2x3
          data={block}
          setIsOpen={setIsOpen}
          setPhotoIndex={setPhotoIndex}
          lightboxImages={lightboxImages}
        />
      );
    }
  };

  return (
    <>
      <section className="gallery-inner-sec">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="collection-breadcrumb">
                <div className="row">
                  <h3 className="col-12 col-lg-10">{blocks?.Title}</h3>
                </div>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home </Link>
                    </li>
                    <li className="breadcrumb-item active">{blocks?.Title}</li>
                  </ol>
                </nav>
              </div>
              <div
                className="text-block"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(blocks?.BodyCopy),
                }}
              ></div>
              <br />
              <div className="gallery-inner-box dasktop-version">
                {blocks?.GallaryContent?.map((block) => getComponent(block))}
              </div>
              <div className="gallery-inner-box mobile-version">
                <div className="row">
                  {blocks?.GallaryContent?.map((block) =>
                    getMobileComponent(block)
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {isOpen && (
        <Lightbox
          mainSrc={lightboxImages[photoIndex]}
          nextSrc={lightboxImages.filter(Boolean)[(photoIndex + 1) % lightboxImages.filter(Boolean).length]}
          prevSrc={
            lightboxImages.filter(Boolean)[
              (photoIndex + lightboxImages.filter(Boolean).length - 1) % lightboxImages.filter(Boolean).length
            ]
          }
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex(
              (photoIndex + lightboxImages.filter(Boolean).length - 1) % lightboxImages.filter(Boolean).length
            )
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % lightboxImages.filter(Boolean).length)
          }
          imagePadding={40}
          imageCaption={
            lightboxLabels[photoIndex] &&
            <LightboxCaption
              title={lightboxLabels[photoIndex]}
              desc={lightboxCaptions[photoIndex]}
            />
          }
        />
      )}
    </>
  );
};

export default GalleryPage;
