import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/style.css';
import 'react-image-lightbox/style.css';
import './styles/responsive.css';
import * as React from 'react';
import { render, hydrate } from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import history from './utils/history';
import 'sanitize.css/sanitize.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './containers/App';
import LanguageProvider from './containers/LanguageProvider';
import configureStore from './configureStore';
import { translationMessages } from './i18n';

const initialState = {};
const store = configureStore(initialState, history);
const MOUNT_NODE = document.getElementById('root') as HTMLElement;

const defRender = (messages: any, Component = App) => {
    if (MOUNT_NODE.hasChildNodes()) {
        hydrate(<Provider store={store}>
            <LanguageProvider messages={messages}>
                <ConnectedRouter history={history}>
                    <Component />
                </ConnectedRouter>
            </LanguageProvider>
        </Provider>, MOUNT_NODE);
    } else {
        render(<Provider store={store}>
            <LanguageProvider messages={messages}>
                <ConnectedRouter history={history}>
                    <Component />
                </ConnectedRouter>
            </LanguageProvider>
        </Provider>, MOUNT_NODE);
    }
};


// Chunked polyfill for browsers without Intl support
if (!(window as any).Intl) {
    new Promise(resolve => {
        resolve(import('intl'));
    })
        // .then(() => Promise.all([import('./intl/locale-data/jsonp/en.js'), import('./intl/locale-data/jsonp/de.js')]))
        .then(() => defRender(translationMessages))
        .catch(err => {
            throw err;
        });
} else {
    defRender(translationMessages);
}

// Install ServiceWorker and AppCache in the end since
// it's not most important operation and if main code fails,
// we do not want it installed
//if (process.env.NODE_ENV === 'production') {
//    require('offline-plugin/runtime').install();
//}
