import React from "react";
import colonial1 from "../../assets/images/colonial-grades-img1.png";
import colonial2 from "../../assets/images/colonial-grades-img2.png";
import { Collection } from "../../models/collection";

const createDOMPurify = require("dompurify");
const { JSDOM } = require("jsdom");

const window = new JSDOM("").window;
const DOMPurify = createDOMPurify(window);

interface Props {
  collectionData: Collection;
}

const CollectionGradeDetail = (props: Props) => {
  const collectionData = props.collectionData;

  return (
    <section className="colonial-grades-sec">
      <div className="container">
        {collectionData?.ContentSections?.map((block) =>
          block.__component === "page-components.left-content-section" ? (
            <div className="colonial-grades-box">
              <div className="row no-gutters">
                <div className="col-md-6">
                  <div className="image-holder">
                    <img className="img-fluid" src={block?.Image?.url} alt="" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="text-box">
                    <h3>{block?.Title}</h3>
                    <span>{block?.SubTitle}</span>
                    <p
                      className="text-block"
                      dangerouslySetInnerHTML={{
                        __html: block?.Description,
                      }}
                    ></p>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="colonial-grades-box colonial-grades-box-second">
              <div className="row no-gutters">
                <div className="col-md-6 order-md-2">
                  <div className="image-holder">
                    <img className="img-fluid" src={block?.Image?.url} alt="" />
                  </div>
                </div>
                <div className="col-md-6 order-md-1">
                  <div className="text-box">
                    <h3>{block.Title}</h3>
                    <span>{block?.SubTitle}</span>
                    <p
                      className="text-block"
                      dangerouslySetInnerHTML={{
                        __html: block?.Description,
                      }}
                    ></p>
                  </div>
                </div>
              </div>
            </div>
          )
        )}
      </div>
    </section>
  );
};

export default CollectionGradeDetail;
