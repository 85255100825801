import React from "react";

const Gallery2x3 = (props) => {
  const data = props.data;
  const setIsOpen = props.setIsOpen;
  const lightboxImages = props.lightboxImages;
  const setPhotoIndex = props.setPhotoIndex;
  console.log(data)

  return (
    <>
      <div className="gallery-inner-box dasktop-version">
        <div className="row">
          <div className="col-md-4 col-6">
            <div className="image-holder small-left">
              <img src={!data.TopLeft ? '' : data.TopLeft.url} alt="" onClick={() => {
                  setIsOpen(true);
                  setPhotoIndex(lightboxImages.indexOf(data.TopLeft.url));
                }} />
            </div>
          </div>
          <div className="col-md-4 col-6">
            <div className="image-holder">
              <img src={!data.TopMiddle ? '' : data.TopMiddle.url} alt="" onClick={() => {
                  setIsOpen(true);
                  setPhotoIndex(lightboxImages.indexOf(data.TopMiddle.url));
                }} />
            </div>
          </div>
          <div className="col-md-4 col-6">
            <div className="image-holder small-right">
              <img src={!data.TopRight ? '' : data.TopRight.url} alt="" onClick={() => {
                  setIsOpen(true);
                  setPhotoIndex(lightboxImages.indexOf(data.TopRight.url));
                }} />
            </div>
          </div>
          <div className="col-md-4 col-6">
            <div className="image-holder small-left">
              <img src={!data.BottomLeft ? '' : data.BottomLeft.url} alt="" onClick={() => {
                  setIsOpen(true);
                  setPhotoIndex(lightboxImages.indexOf(data.BottomLeft.url));
                }} />
            </div>
          </div>
          <div className="col-md-4 col-6">
            <div className="image-holder">
              <img src={!data.BottomMiddle ? '' : data.BottomMiddle.url} alt="" onClick={() => {
                  setIsOpen(true);
                  setPhotoIndex(lightboxImages.indexOf(data.BottomMiddle.url));
                }} />
            </div>
          </div>
          <div className="col-md-4 col-6">
            <div className="image-holder small-right">
              <img src={!data.BottomRight ? '' : data.BottomRight.url} alt="" onClick={() => {
                  setIsOpen(true);
                  setPhotoIndex(lightboxImages.indexOf(data.BottomRight.url));
                }} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Gallery2x3;
