import React, { useState } from "react";
import { Link } from "react-router-dom"
import Dropdown from "react-bootstrap/Dropdown";
import { SearchResult } from "../../models/searchResult";
import { Tags } from "../../models/filterTags";

interface Props {
  searchData: SearchResult[];
  searchTerm: String;
  tags: Tags[]
}

// would allow copy/paste search url for sharing
const reverseTheSlug = (fromSlug) => {
  return fromSlug.split('-').join(' ')
}

const SearchResults = (props: Props) => {
  const searchData = props.searchData;
  const searchTerm = props.searchTerm;
  const tags = props.tags;
  const [currentCategory, setCurrentCategory] = useState('Category')

  const isFiltered = (results) => {
    if (currentCategory !== 'Category') {
      return results.map(item => ({
        ...item,
        tags: item.tags
          .filter(tag => tag.name.includes(currentCategory))
      }))
      .filter(item => item.tags.length > 0)
    } 
    return results
  }

  return (
    <section className="search-result-sec">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="search-result-inner">
              <div className="search-result-detail">
                <div className="title">
                  <h2>Search Results</h2>
                  <span>Search results for "{reverseTheSlug(searchTerm)}" </span>
                </div>
                <div className="filter-options">
                  <a href="#">
                    filter options
                    <img src="img/down-arrow-icon.svg" alt="" />
                  </a>
                </div>
                <ul>
                  <li id="search-filter">
                    <Dropdown className="dropdown">
                      <Dropdown.Toggle as="a" id="dropdown-custom-components">
                        <a
                          href="#"
                          id="dropdownMenuButton"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          filter by <span>{currentCategory}</span>
                          <img src="img/down-arrow-icon.svg" alt="" />
                        </a>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item onSelect={() => setCurrentCategory('Category')} eventKey={"ALL"}>ALL</Dropdown.Item>
                        {tags?.map((item) => 
                          <Dropdown.Item onSelect={() => setCurrentCategory(item.name)} eventKey={item.name}>{item.name}</Dropdown.Item>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </li>
                </ul>
              </div>
              <div className="search-result-box">
                <div className="row">
                  {isFiltered(searchData)?.map((result) => (
                    <div className="col-md-4">
                      <div className="text-box">
                  <p>{result?.CollectionTitle}</p>
                  <Link to={`/collections/${result?.Slug}`}><h3>{result?.CollectionName}</h3></Link>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SearchResults;
