import React from "react";
import Slideshow from "../Common/Slideshow";
import collectionLogo from "../../assets/images/collection-logo.png";
import { Collection } from "../../models/collection";
import { homePage } from "../../models/homePage";

const createDOMPurify = require("dompurify");
const { JSDOM } = require("jsdom");

const window = new JSDOM("").window;
const DOMPurify = createDOMPurify(window);

interface Props {
  collectionData: Collection;
  siteConfig: homePage;
}

const CollectionSlideshow = (props: Props) => {
  const collectionData = props.collectionData;
  const siteConfig = props.siteConfig;

  return (
    <>
    {collectionData?.CustomBottomContent === true && 
    <section className="slideshow-sec">
      <div className="container">
        <div className="row">
          <div className="col-12">
              <div className="text-box">
                <span>
                  <img
                    className="img-fluid"
                    src={collectionData?.BottomLogo?.url}
                    alt=""
                  />
                </span>
                <p
                  className="collection-bottom-desc text-block"
                  dangerouslySetInnerHTML={{
                    __html: 
                      collectionData?.BottomDescription
                    ,
                  }}
                ></p>
              </div>
          </div>
        </div>
      </div>
    </section>}
    {siteConfig?.MainHomeFooterCarousel?.length !== 0 &&
      <Slideshow slideData={siteConfig} />}
    </>
  );
};

export default CollectionSlideshow;
