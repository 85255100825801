import React from "react";

const createDOMPurify = require("dompurify");
const { JSDOM } = require("jsdom");

const window = new JSDOM("").window;
const DOMPurify = createDOMPurify(window);

const TextContent = (props) => {
  const data = props.data;
  return (
    <section className="tempate-qualtiy">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="text-box title">
              <span>{data?.SmallTitle}</span>
              <div className="row">
                <h3 className="col-12 col-lg-10">{data?.SectionHeading}</h3>
              </div>
            </div>
            <div
              className="text-block"
              dangerouslySetInnerHTML={{
                __html: data?.Content,
              }}
            ></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TextContent;
