import React from "react";
import PageHeader from "./blockTypes/PageHeader";
import TextContent from "./blockTypes/TextContent";
import TextWithBackground from "./blockTypes/TextWithBackground";
import ClientsContent from "./blockTypes/ClientsContent";
import { customPage } from "../../models/customPage";
import PageHeaderWithImageAndText from "./blockTypes/PageHeaderWithImageAndText";
import LeftContentSection from "./blockTypes/LeftContentSection";
import RightContentSection from "./blockTypes/RightContentSection";

interface Props {
  pageContent: customPage | null;
}

const CustomPageDetail = (props: Props) => {
  const blocks = props.pageContent?.bodyContent;
  const headerBlock = props.pageContent?.headerContent;

  const getBlockComponent = (block) => {
    console.log(block)

    if (block.__component === "page-components.heritage-section") {
      return <TextWithBackground data={block} />;
    }

    if (block.__component === "page-components.our-clients") {
      return <ClientsContent data={block} />;
    }

    if(Object.keys(block)[0] !== "__component") {
      return <PageHeaderWithImageAndText data={block} />;
    }

    if (block.__component === "page-components.text-content") {
      return <TextContent data={block} />;
    }

    if (block.__component === "page-components.left-content-section") {
      return <LeftContentSection data={block} />;
    }

    if (block.__component === "page-components.right-content-section") {
      return <RightContentSection data={block} />;
    }

  };

  return (

  <>
    <div className="blocks_loop template-page">
      {headerBlock && (
          <>
            {getBlockComponent(headerBlock)}
          </>
      )}
    </div>
    <div className="blocks_loop template-page">
      {blocks && (
          <>
            {blocks.map((block) => getBlockComponent(block))}
          </>
      )}
    </div>
    </>
  );
};

export default CustomPageDetail;
