import React from "react";
import { renderToString } from 'react-dom/server'
import 'owl.carousel/dist/assets/owl.carousel.css';
import '../../styles/owl.theme.default.css';
import slider1 from "../../assets/images/slider-img1.jpg"
import slider2 from "../../assets/images/slider-img2.jpg"
import slider3 from "../../assets/images/slider-img3.jpg"
import { homePage } from "../../models/homePage"
import Loadable from '@loadable/component';
const OwlCarousel = Loadable(() => import('react-owl-carousel'));
interface Arrows {
    leftArrow: string,
    rightArrow: string,
}

interface Props {
    slideData?: homePage
}

let arrows:Arrows = {} as any;
arrows.leftArrow = renderToString(<img className="img-fluid carousel-control-prev-icon" src={require('../../assets/images/previous-arrow.svg')} alt="" />);
arrows.rightArrow = renderToString(<img className="img-fluid carousel-control-next-icon" src={require('../../assets/images/next-arrow.svg')} alt="" />);

const SlideShow = (props: Props) => {
    const slideData = props.slideData

    return(
        <section className="slideshow-sec">
            <OwlCarousel
                items={3}
                className="owl-carousel owl-theme"
                loop
                nav
                draggable={false}
                navText={[arrows.leftArrow, arrows.rightArrow]}
                dots={false}
                margin={20}>
                {slideData && slideData?.MainHomeFooterCarousel![0]?.FooterImages?.map(img =>
                <div className="item">
                    <div className="image-holder">
                        <img className="img-fluid" src={img?.url} alt="" />
                    </div>
                </div>)}
            </OwlCarousel>
        </section>
    )
}

export default SlideShow;
