import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { watchGetCustomPage } from "../../redux/actions";
import PropTypes from "prop-types";
import CustomPageDetail from "../../components/CustomPageDetails";

const CustomPage = ({ slug, pageData, home, watchGetCustomPage }) => {
  useEffect(() => {
    //API HTTP GET
    watchGetCustomPage(slug);
  }, [watchGetCustomPage, home, slug]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [slug]);

  const reverseTheSlug = (fromSlug) => {
    return fromSlug
      .split("-")
      .join(" ")
      .replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
  };

  return (
    <>
      <Helmet>
        <title>{reverseTheSlug(slug)}</title>
      </Helmet>
      <CustomPageDetail pageContent={pageData} />
    </>
  );
};

CustomPage.propTypes = {
  apiLoading: PropTypes.bool,
  pageData: PropTypes.object.isRequired,
  watchGetCustomPage: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  apiLoading: state.apiReducer.apiLoading,
  pageData: state.apiReducer.customPage,
  home: state.apiReducer.homepageData,
  slug: state.router.location.pathname.split("/")[2],
});

export default connect(mapStateToProps, { watchGetCustomPage })(CustomPage);
