import React from "react";
import usaLogo from "../../assets/images/usa-logo.jpg";
import americanHardwoodLogo from "../../assets/images/american-hardwood-logo.jpg";
import certifiedLogo from "../../assets/images/certified-logo.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagramSquare } from "@fortawesome/free-brands-svg-icons";
import { connect } from "react-redux";
import PropTypes from "prop-types";

function Footer({ apiLoading, homepageData }) {
  return (
    <footer className="footer-sec">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="footer-inenr">
              <div className="text-box">
                <ul>
                  {homepageData?.SocialNetworks?.map((media, id) => (
                    <li key={id}>
                      <a href={media.Link}>
                        {media?.DisplayName?.toLowerCase() !== "instagram" ? (
                          <i
                            className={`fa fa-${media?.DisplayName?.toLowerCase()}-square fa-3x`}
                          ></i>
                        ) : (
                          <FontAwesomeIcon icon={faInstagramSquare} size="3x" />
                        )}
                      </a>
                    </li>
                  ))}
                </ul>
                <p>
                  office (870) 367-2436 <span>Fax (870) 367-2968</span>
                </p>
                <p>190 wilson mill road, monticello, arkansas 71655</p>
              </div>
              <div className="copyright">
                <p>
                  copyright ©2020 <br /> maxwell hardwood flooring{" "}
                  <span>
                    <a href="https://doc4design.com/">arkansas design</a>
                  </span>
                </p>
              </div>
              <div className="footer-logo">
                <ul>
                  {homepageData?.FooterLogos?.map((logo, id) => (
                    <li key={id}>
                      <a className="img-fluid" href={logo?.Link}>
                        <img src={logo?.LogoImage?.url} alt="" />
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  apiLoading: PropTypes.bool,
  homepageData: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  apiLoading: state.apiReducer.apiLoading,
  homepageData: state.apiReducer.homepageData,
});

export default connect(mapStateToProps)(Footer);
