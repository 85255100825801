import React from "react";

const createDOMPurify = require("dompurify");
const { JSDOM } = require("jsdom");

const window = new JSDOM("").window;
const DOMPurify = createDOMPurify(window);

const ClientsContent = (props) => {
  const data = props.data;

  return (
    <section className="template-millions-sec">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="template-title">
              <h3>
                <span>{data?.Title}</span>
              </h3>
              <p
                className="text-block"
                dangerouslySetInnerHTML={{
                  __html: data?.Content,
                }}
              ></p>
            </div>
            <div className="template-millions-inner dasktop-version">
              <ul>
                {data?.Clients?.map((client) => (
                  <li>
                    <img
                      className="img-fluid"
                      src={client?.Logo?.url}
                      alt={client?.alternativeText}
                    />
                  </li>
                ))}
              </ul>
            </div>
            <div className="template-millions-inner mobile-version">
            <div className="row">
              {data?.Clients?.map((client) => (
                 <div className="col-6">
                   <img
                     className="img-fluid"
                     src={client?.Logo?.url}
                     alt={client?.alternativeText}
                   />
                 </div>
              ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ClientsContent;
