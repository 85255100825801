import React, { useEffect } from "react";
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

interface Props {
    title?: string;
    desc?: string;
}

const LightboxCaption = (props: Props) => {
    const title = props.title;
    const desc = props.desc;

    const targetElement = document.getElementsByClassName('ril-caption-content ril__captionContent');

    useEffect(() => {
        disableBodyScroll(targetElement);
    })
    
    useEffect(() => {
        return () => {
            clearAllBodyScrollLocks();
        }
    })
    
    return(
        <div className="lightbox-caption">
            <h1 className="caption-title">{title}</h1>
            <p className="caption-desc text-block">{desc}</p>
        </div>
    )
}

export default LightboxCaption;