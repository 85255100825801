import React, { useEffect, useState } from 'react';
import {Helmet} from "react-helmet";
import Gallery from "../../components/Gallery"
//import galleryData from "../../components/Gallery/mockGalleryBlock.json"
import { connect } from 'react-redux'
import { watchGetGalleryPage } from "../../redux/actions"
import PropTypes from 'prop-types';
import { useInjectSaga } from "../../utils/injectSaga";
import rootSaga from "../../redux/sagas/rootSaga"

const GalleryPage = ({ watchGetGalleryPage, apiLoading, galleryPage }) => {
    useInjectSaga({ key: "apiReducer", saga: rootSaga });
    const [galleryData, setGalleryData] = useState(null)

    useEffect(() => {
        //API HTTP GET
        watchGetGalleryPage()
    }, [watchGetGalleryPage])

    useEffect(() => {
        setGalleryData(galleryPage)
    }, [setGalleryData, apiLoading, galleryPage])
    
    return(
        <>
        <Helmet>
			<title>Gallery</title>
		</Helmet>
        <Gallery blocks={galleryData} />
        </>
    )
}

GalleryPage.propTypes = {
    apiLoading: PropTypes.bool,
    galleryPage: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
    apiLoading: state.apiReducer.apiLoading,
    galleryPage: state.apiReducer.galleryPage,
  });

export default connect(mapStateToProps, { watchGetGalleryPage })(GalleryPage);